exports.components = {
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-404-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/404.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-404-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-blogi-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/blogi.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-blogi-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-index-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/index.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-index-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-aloittaville-yrityksille-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/palvelut/aloittaville-yrityksille.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-aloittaville-yrityksille-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-digiloikkaajille-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/palvelut/digiloikkaajille.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-digiloikkaajille-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-index-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/palvelut/index.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-index-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-it-korporaatioille-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/palvelut/it-korporaatioille.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-palvelut-it-korporaatioille-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-rekisteri-ja-tietosuojaseloste-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/rekisteri-ja-tietosuojaseloste.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-rekisteri-ja-tietosuojaseloste-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-yhteystiedot-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/yhteystiedot.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-yhteystiedot-mdx" */),
  "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-yritys-mdx": () => import("./../../../src/templates/PageTemplate.js?__contentFilePath=/opt/buildhome/repo/content/pages/yritys.mdx" /* webpackChunkName: "component---src-templates-page-template-js-content-file-path-opt-buildhome-repo-content-pages-yritys-mdx" */)
}

